<template>

    <v-container>
        <div >
          <v-row justify="center">
            <v-col col="12">
              <h2 class="font-weight-normal mb-4">
                When you can't sign in to your Etousoft account
              </h2>
              <p class="">
                If you're worried that someone gained unauthorized access to
                your account, see <a :href="recover">Recover</a> your
                Etousoft account and Etousoft account security info &
                verification codes.
              </p>
              <p class="">
                To get back into your account as quickly as possible, try these
                basic troubleshooting steps:
              </p>
              <ol>
                <li class="mb-1">
                  Verify that Caps Lock is turned off and your email address is
                  spelled correctly.
                </li>
                <li class=" mb-1">
                  Clear your browser history.
                </li>
                <li class=" mb-1">
                  Try signing in to your account from a different internet
                  browser.
                </li>
              </ol>
            </v-col>
          </v-row>
        </div>

        <div>
          <v-row justify="center">
            <v-col col="12">
              <h2 class="font-weight-normal mb-4">
                If you can't remember your password or it's not working
              </h2>
              <p class="">
                You can reset your Microsoft account password by following these
                steps:
              </p>
              <ol>
                <li class=" mb-1">
                  Go to the
                  <a :href="recover">Recover your account</a> page
                </li>
                <li class=" mb-1">
                  Enter the email of your Etousoft account
                </li>
                <li class=" mb-1">
                  We will send you a link to recover your password
                </li>
                <li class=" mb-1">
                  Enter your new password, then select Next.
                </li>
              </ol>
            </v-col>
          </v-row>
        </div>

   
    </v-container>

</template>

<script>
export default {
  data()
  {
    return {
      recover : null
    }
  },
  created()
  {
    this.recover = this.$config.base + '/forgot_password'
  }
};
</script>

<style scoped>
#btn {
  text-transform: none;
}
</style>